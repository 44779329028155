import React, { useState, useEffect, memo } from "react";
import { Divider, Paper, TextField } from "@mui/material";
import { ImageViewer } from "components/upload/imageViewer";
import ImageModal from "components/images/Imagemodal";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import CustomButton from "../inputs/customButton";
import { offerDetails, createOffer, updateOffer } from "api/offers";
import { toast, ToastContainer } from "react-toastify";
import { toastStyle, TimeFormat } from "components/helper";
import "react-toastify/dist/ReactToastify.css";
import { getCookie } from "components/helper/cookies";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import ImageSearchOutlinedIcon from "@mui/icons-material/ImageSearchOutlined";
import moment from "moment";
import FileDownloadDoneOutlinedIcon from "@mui/icons-material/FileDownloadDoneOutlined";
import OfferModal from "components/modals/offerModals";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

function OfferForm({ handleSearchshow, handleLoader }) {
  const [showImageModal, setShowImageModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageName, setImageName] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [stateData, setStateData] = useState({
    expiry_date: dayjs().format("MM/DD/YYYY"),
    start_date: dayjs().format("MM/DD/YYYY"),
    rentals: [],
    status: "active",
    ar_image: [],
    en_image: [],
    offer_type: ""
  });
  const { id } = stateData;

  const title = id ? "Update Offer" : "Create Offer";
  const path = window.location.pathname.split("/")[2];

  useEffect(() => {
    handleSearchshow(false);
    path !== "createoffer" && fetchmodelDetails(path);
  }, []);

  const fetchmodelDetails = async (id) => {
    handleLoader(true);
    await offerDetails(getCookie("auth_token"), id).then(({ data }) => {
      setStateData(data);
      handleLoader(false);
    });
  };

  const handleSubmit = () => {
    const {
      en_title,
      ar_title,
      expiry_date,
      status,
      ar_image,
      en_image,
      rentals,
      start_date,
      percentage,
      offer_type
    } = stateData;
    setDisabled(true);
    handleLoader(true);

    let requestBody = {
      offers: {
        en_title,
        ar_title,
        start_date: moment(new Date(start_date)).format("YYYY/MM/DD"),
        expiry_date: moment(new Date(expiry_date)).format("YYYY/MM/DD"),
        status,
        ar_image_id: ar_image.id,
        en_image_id: en_image.id,
        rental_ids: rentals.map((data) => {
          return data.id;
        }),
        percentage,
        offer_type
      },
    };

    const action = stateData?.id ? updateOffer : createOffer;
    action(getCookie("auth_token"), requestBody, stateData?.id)
      .then(({ message }) => {
        toast.success(message, toastStyle);
        handleLoader(false);
        setTimeout(() => {
          window.location.pathname = `/offers`;
        }, 2000);
      })
      .catch((err) => {
        if (err[1]) {
          toast.error(
            err[1] ? err[1]?.data[0] : "Something not right",
            toastStyle
          );
        } else {
          toast.error(err.message, toastStyle);
        }
      });
  };

  const handleOk = (selectedCars) => {
    setIsModalOpen(false);
    setDisabled(false);
    setStateData((preState) => ({ ...preState, rentals: selectedCars }));
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleStatus = (event, name) => {
    setDisabled(false);
    setStateData((preState) => ({ ...preState, [name]: event.target.value }));
  };

  const handleDate = (date, name) => {
    setDisabled(false);
    setStateData((preState) => ({
      ...preState,
      [name]: moment(new Date(date)).format("MM/DD/YYYY"),
    }));
  };

  const handleModel = (name) => {
    setDisabled(false);
    setShowImageModal(!showImageModal);
    setImageName(name);
  };

  const handleChange = (event) => {
    setDisabled(false);
    const { name, value } = event.target;
    setStateData((preState) => ({ ...preState, [name]: value }));
  };

  const onModalOk = (image_name, name) => {
    setDisabled(false);
    setStateData((preState) => ({ ...preState, [name]: image_name }));
    setShowImageModal(false);
  };

  const imagedata = {
    showImageModal,
    handleModel,
    onModalOk,
    multi: false,
    imageName,
  };

  const offerModalProps = {
    handleOk,
    handleCancel,
    defaultSelected: stateData.rentals,
    offer_type: stateData.offer_type,
  };

  const { rentals } = stateData;
  return (
    <>
      <Paper
        elevation={0}
        style={{
          backgroundColor: "rgb(244, 245, 250)",
        }}
      >
        <div
          style={{
            backgroundColor: "rgb(244, 245, 250)",
            padding: "1.25rem 1.25rem 0.75rem",
            width: "auto",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              textTransform: "capitalize",
              margin: 0,
              fontFamily: "Inter, sans-serif",
              fontSize: "1.5rem",
              lineHeight: 1.334,
              letterSpacing: "0em",
              fontWeight: 500,
            }}
          >
            {title}
            <div
              style={{
                fontSize: "0.8rem",
                fontWeight: 500,
                color: "rgba(58, 53, 65, 0.38)",
                paddingTop: 10,
              }}
            >
              {stateData.updated_by
                ? `Updated by ${stateData.updated_by?.name}`
                : stateData.created_by
                ? `Created by ${stateData.created_by?.name}`
                : " "}{" "}
              {stateData.updated_by && stateData?.updated_at
                ? TimeFormat(stateData?.updated_at)
                : stateData?.created_by && stateData?.created_at
                ? TimeFormat(stateData?.created_at)
                : " "}
            </div>
          </div>
          <div>
            <CustomButton
              color="success"
              onClick={handleSubmit}
              text={id ? "Update" : "Create"}
              disabled={disabled}
            />
          </div>
        </div>

        <Divider />
        <div
          style={{
            padding: "0 20px ",
            backgroundColor: "rgb(255, 255, 255)",
            margin: "20px ",
            boxShadow: "rgba(58, 53, 65, 0.1) 0px 2px 10px 0px",
            borderRadius: " 16px",
            transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          }}
        >
          <div
            style={{
              display: "flex",
              height: 290,
            }}
          >
            <div style={{ flex: 3 }}>
              <div
                style={{
                  padding: "20px 10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: "100%", padding: "10px" }}>
                  <TextField
                    id="outlined-multiline-static"
                    label="Offer Title - English"
                    name="en_title"
                    value={stateData?.en_title ? stateData?.en_title : ""}
                    style={{ width: "100%" }}
                    onChange={handleChange}
                  />
                </div>
                <div style={{ width: "100%", padding: "10px" }}>
                  <TextField
                    id="outlined-multiline-static"
                    label="Offer Title - Arabic"
                    name="ar_title"
                    value={stateData?.ar_title ? stateData?.ar_title : ""}
                    style={{ width: "100%" }}
                    onChange={handleChange}
                  />
                </div>
                {/* <div style={{ width: "100%", padding: "10px" }}>
                  <TextField
                    id="outlined-multiline-static"
                    label="Percentage"
                    name="percentage"
                    value={stateData?.percentage ? stateData?.percentage : ""}
                    style={{ width: "100%" }}
                    onChange={handleChange}
                  />
                </div> */}
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "10px 20px",
                }}
              >
                <div style={{ padding: "10px" }}>
                  <ToggleButtonGroup
                    value={stateData?.status ? stateData?.status : "active"}
                    exclusive
                    onChange={(event) => handleStatus(event, "status")}
                    color="primary"
                    name="status"
                  >
                    <ToggleButton value="active" style={{ width: "100px" }}>
                      Active
                    </ToggleButton>
                    <ToggleButton value="inactive" style={{ width: "100px" }}>
                      InActive
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    views={["day", "month", "year"]}
                    label="Offer Start"
                    name="start_date"
                    value={stateData?.start_date ? stateData?.start_date : ""}
                    onChange={(newValue) => {
                      handleDate(newValue, "start_date");
                    }}
                    disablePast
                    renderInput={(params) => (
                      <TextField {...params} helperText={null} />
                    )}
                  />
                </LocalizationProvider>
                <div style={{ width: "35%", padding: "0 10px" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDatePicker
                      views={["day", "month", "year"]}
                      label="Offer Expiry"
                      name="expiry_date"
                      value={
                        stateData?.expiry_date ? stateData?.expiry_date : ""
                      }
                      onChange={(newValue) => {
                        handleDate(newValue, "expiry_date");
                      }}
                      disablePast
                      renderInput={(params) => (
                        <TextField {...params} helperText={null} />
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  padding: "10px 20px",
                }}
              >
                <div style={{ width: "35%", padding: "0 10px" }}>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Offer Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={stateData.offer_type}
                      label="Offer Type"
                      name="offer_type"
                      onChange={handleChange}
                    >
                      <MenuItem value={"rental"}>Rental</MenuItem>
                      <MenuItem value={"preowned"}>Preowned</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <CustomButton
                  onClick={() => setIsModalOpen(true)}
                  text="Select Car"
                  color="success"
                />
              </div>
            </div>
            <div
              style={{
                flex: 2,
                height: 300,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ flex: 1, padding: "10px" }}>
                <p
                  style={{
                    fontFamily: "Inter, sans-serif",
                    lineHeight: 1.5,
                    letterSpacing: "0.15px",
                    color: " rgba(58, 53, 65, 0.87)",
                    fontWeight: 600,
                    fontSize: " 1rem",
                    textAlign: "center",
                    margin: 0,
                  }}
                >
                  Offer Image - English
                </p>
                <ImageViewer
                  placeholder="Upload Image"
                  onHandleClick={() => {
                    handleModel("en_image");
                  }}
                  name="en_image"
                  value={stateData?.en_image ? stateData?.en_image : ""}
                />
              </div>
              <div style={{ flex: 1, padding: "10px" }}>
                <p
                  style={{
                    fontFamily: "Inter, sans-serif",
                    lineHeight: 1.5,
                    letterSpacing: "0.15px",
                    color: " rgba(58, 53, 65, 0.87)",
                    fontWeight: 600,
                    fontSize: " 1rem",
                    textAlign: "center",
                    margin: 0,
                  }}
                >
                  Offer Image - Arabic
                </p>
                <ImageViewer
                  placeholder="Upload Image"
                  onHandleClick={() => {
                    handleModel("ar_image");
                  }}
                  name="ar_image"
                  value={stateData?.ar_image?.url ? stateData?.ar_image : ""}
                />
              </div>
            </div>
          </div>
          <div>
            {rentals.length > 0 && (
              <div style={{ display: "flex", flex: 1, padding: "0 20px" }}>
                <span
                  style={{
                    backgroundColor: "rgb(255, 180, 0)",
                    color: "rgb(255, 255, 255)",
                    borderRadius: 5,
                    overflow: "hidden",
                    fontSize: "1.25rem",
                    textAlign: "center",
                    height: 50,
                    width: 50,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "1rem",
                    boxShadow: "rgba(58, 53, 65, 0.42) 0px 4px 8px -4px",
                  }}
                >
                  <FileDownloadDoneOutlinedIcon
                    style={{ fontSize: "1.5rem" }}
                  />
                </span>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      fontFamily: "Inter, sans-serif ",
                      fontWeight: 500,
                      fontSize: "1.25rem",
                      lineHeight: 1.6,
                      letterSpacing: "0.15px",
                      color: " rgba(58, 53, 65, 0.87)",
                    }}
                  >
                    Selected Cars{" "}
                  </span>
                  <span
                    style={{
                      fontFamily: "Inter, sans-serif ",
                      fontWeight: 500,
                      fontSize: "1.25rem",
                      lineHeight: 1.6,
                      letterSpacing: "0.15px",
                      color: " rgba(58, 53, 65, 0.87)",
                    }}
                  >
                    (
                    {rentals?.length > 0
                      ? rentals?.length
                      : stateData?.cars_count}
                    )
                  </span>
                </div>
              </div>
            )}
          </div>
          <div
            style={{
              display: "flex",
              gap: 10,
              flexWrap: "wrap",
              padding: "20px 40px",
            }}
          >
            {" "}
            {rentals !== undefined &&
              rentals?.map((value, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      flexBasis: "23%",
                      flexGrow: 0,
                      padding: "10px",
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "center",
                      backgroundColor: " rgb(255, 255, 255)",
                      color: "rgba(58, 53, 65, 0.87)",
                      transition:
                        "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                      borderRadius: "6px",
                      overflow: "hidden",
                      boxShadow: " rgba(58, 53, 65, 0.1) 0px 2px 10px 0px",
                      justifyContent: "center",
                      alignItems: "center",
                      marginLeft: "10px",
                      minHeight: "125px",
                      filter:
                        value?.status === "inactive"
                          ? " grayscale(100%)"
                          : "none",
                      opacity: value?.status === "inactive" ? 0.6 : "none",
                    }}
                    className="selected-cars"
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        textAlign: "center",
                        position: "relative",
                      }}
                    >
                      <div style={{ flex: 0.5 }}>
                        {value?.title_image_url || value?.title_image?.url ? (
                          <img
                            src={
                              value?.title_image?.url
                                ? value?.title_image?.url
                                : value?.title_image_url
                            }
                            style={{
                              height: "100px",
                              width: "150px",
                              objectFit: "scale-down",
                            }}
                          />
                        ) : (
                          <ImageSearchOutlinedIcon
                            style={{
                              height: "100px",
                              width: "150px",
                              objectFit: "scale-down",
                            }}
                          />
                        )}
                      </div>
                      <div
                        style={{
                          flex: 1,
                          height: "100%",
                          textAlign: "left",
                          marginLeft: "10px",
                        }}
                      >
                        <p
                          style={{
                            margin: 0,
                            fontFamily: "Inter, sans-serif",
                            fontSize: "1rem",
                            fontWeight: 400,
                            color: "rgba(58, 53, 65, 0.87)",
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          {value.brand}
                        </p>
                        <p
                          style={{
                            margin: 0,
                            fontFamily: "Inter, sans-serif",
                            fontSize: "1rem",
                            fontWeight: 700,
                            color: "rgba(58, 53, 65, 0.87)",
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          {value.en_model_name}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          {isModalOpen && <OfferModal {...offerModalProps} />}
          {showImageModal && <ImageModal {...imagedata} />}
        </div>

        <ToastContainer style={{ zIndex: 999999 }} />
      </Paper>
    </>
  );
}
export default memo(OfferForm);
