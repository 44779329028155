import React, { useState, useEffect, memo } from "react";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Grid from "@mui/material/Grid";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import InputAdornment from "@mui/material/InputAdornment";
import CustomButton from "../inputs/customButton";
import ImageModal from "components/images/Imagemodal";
import { ImageViewer } from "components/upload/imageViewer";
import { getCookie } from "components/helper/cookies";
import {
  preOwnedCarCreate,
  preOwnedCarUpdate,
  preOwnedCarDetails,
} from "api/car";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TimeFormat, toastStyle } from "../helper";
import { TinyMceField } from "components/inputs/tinymce";
import Paper from "@mui/material/Paper";
import moment from "moment";
import { fetchOffers } from "api/offers";
import { fetchBrands } from "api/brands";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      width: 250,
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};


function PreOwnedForm({ handleSearchshow, handleLoader }) {
  const [stateData, setStateData] = useState({
    transmission: "automatic",
    fuel_type: "gas",
    car_type: "suv",
    insurance_type: "comprehensive",
    images: "",
    overview: { rto: "", car_location: "", km_driven: "" },
    make_year: dayjs().format("YYYY/MM/DD"),
    register_year: dayjs().format("YYYY/MM/DD"),
    insurance_validity: dayjs().format("YYYY/MM/DD"),
    brand: "",
    status: "active",
    year: null,
    offers: [],
    vsb: "",
  });
  const [showImageModal, setShowImageModal] = useState(false);
  const { id } = stateData;
  const [multiSelect, setMultiSelect] = useState(false);
  const [disable, setDisable] = useState(false);
  const [offersList, setOffersList] = useState([]);
  const [search] = useState({ query: "" });
  const [brands, setBrands] = useState([]);

  const title = id ? "Update Pre-Owned Car" : "Create Pre-Owned Car";

  const handleModel = (info) => {
    setMultiSelect(info);
    setShowImageModal(!showImageModal);
  };

  useEffect(() => {
    handleSearchshow(false);
    handleLoader(false);
    fetchOffer();
    fetchBrand()
    const path = window.location.pathname.split("/")[2];
    path !== "new" && fetchmodelDetails(path);
  }, []);

  const fetchBrand = () => {
    fetchBrands(getCookie("auth_token"))
      .then(({ data }) => {
        setBrands(
          data.map((arr) => {
            return { label: arr.name, value: arr.id };
          })
        );
      })
      .catch((err) => {
        if (err[1]) {
          toast.error(
            err[1] ? err[1]?.data[0] : "Something not right",
            toastStyle
          );
        }
      });
  };

  const fetchmodelDetails = (id) => {
    const currentDate = moment();
    const currentDay = currentDate.format("DD");
    const currentMonth = currentDate.format("MM");
    handleLoader(true);
    preOwnedCarDetails(getCookie("auth_token"), id).then(({ data }) => {
      let ModalYear = `${currentDay}/${currentMonth}/${data?.year}`;
      let newData = {
        ...data,
        offers: data.offers.map((val) => {
          return val.id;
        }),
        brand: [data.car_brand_id],
        year: data?.year
          ? dayjs(ModalYear, "DD/MM/YYYY").format("YYYY/MM/DD")
          : "",
      };
      setStateData(newData);
      handleLoader(false);
    });
  };

  const fetchOffer = () => {
    handleLoader(true);
    const params = {
      page: 1,
      perPage: 100,
    };
    fetchOffers(getCookie("auth_token"), params, search)
      .then(({ data }) => {
        setOffersList(data);
        handleLoader(false);
      })
      .catch((err) => {
        if (err[1]) {
          toast.error(
            err[1] ? err[1]?.data[0] : "Something not right",
            toastStyle
          );
        }
      });
  };

  const handleSubmit = () => {
    const {
      title_image,
      images,
      make_year,
      register_year,
      car_location,
      insurance_type,
      rto,
      insurance_validity,
      km_driven,
      overview,
      year,
      offers,
      brand
    } = stateData;

    handleLoader(true);
    setDisable(true);
    let requestBody = stateData?.id
      ? {
        pre_owns: {
          ...{
            ...stateData,
            car_brand_id: brand,
            offer_ids: offers,
            title_image_id: title_image.id,
            year: parseInt(moment(new Date(year)).format("YYYY")),
            banner_image_id: images.map((value) => {
              return value.id;
            }),
            overviews: {
              make_year: make_year
                ? moment(new Date(make_year)).format("YYYY/MM/DD")
                : moment(new Date(overview?.make_year)).format("YYYY/MM/DD"),
              register_year: register_year
                ? moment(new Date(register_year)).format("YYYY/MM/DD")
                : moment(new Date(overview?.register_year)).format(
                  "YYYY/MM/DD"
                ),
              car_location: car_location
                ? car_location
                : overview?.car_location,
              insurance_type: insurance_type
                ? insurance_type
                : overview?.insurance_type,
              rto: rto ? rto : overview?.rto,
              insurance_validity: insurance_validity
                ? moment(new Date(insurance_validity)).format("YYYY/MM/DD")
                : moment(new Date(overview?.insurance_validity)).format(
                  "YYYY/MM/DD"
                ),
              km_driven: km_driven ? km_driven : overview?.km_driven,
            },
          },
        },
      }
      : title_image && images
        ? {
          pre_owns: {
            ...{
              ...stateData,
              title_image_id: title_image.id,
              year: parseInt(moment(new Date(year)).format("YYYY")),
              banner_image_id: images.map((value) => {
                return value.id;
              }),
              car_brand_id: brand,
              overviews: {
                car_location: stateData?.overview?.car_location,
                rto: stateData?.overview?.rto,
                insurance_validity: moment(
                  new Date(stateData?.insurance_validity)
                ).format("YYYY/MM/DD"),
                km_driven: stateData?.overview?.km_driven,
                make_year: moment(new Date(stateData?.make_year)).format(
                  "YYYY/MM/DD"
                ),
                register_year: moment(
                  new Date(stateData?.register_year)
                ).format("YYYY/MM/DD"),
                insurance_type: stateData?.insurance_type,

              },
            },
          },
        }
        : toast.error("Please select the image", toastStyle);

    const action = stateData?.id ? preOwnedCarUpdate : preOwnedCarCreate;
    action(getCookie("auth_token"), requestBody, stateData?.id)
      .then(({ message }) => {
        toast.success(message, toastStyle);
        handleLoader(false);
        setTimeout(() => {
          window.location.pathname = `/preownedcar`;
        }, 2000);
      })
      .catch((err) => {
        if (err[1]) {
          toast.error(
            err[1] ? err[1]?.data[0] : "Something not right",
            toastStyle
          );
        } else {
          toast.error(err.message, toastStyle);
        }
      });
  };
  const handleOverView = (event) => {
    const { name, value } = event.target;
    setStateData((preState) => ({
      ...preState,
      overview: { ...preState.overview, [name]: value },
    }));
    setDisable(false);
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setStateData((preState) => ({ ...preState, [name]: value }));
    setDisable(false);
  };
  const handleToggle = (event, name) => {
    setStateData((preState) => ({ ...preState, [name]: event.target.value }));
    setDisable(false);
  };
  const onModalOk = (image_name) => {
    multiSelect
      ? setStateData({
        ...stateData,
        images: image_name.multiImageID,
        banner_image_id: image_name.selectedImage,
      })
      : setStateData({
        ...stateData,
        title_image: image_name,
        title_image_id: image_name.id,
      });
    setShowImageModal(!showImageModal);
    setDisable(false);
  };
  const handleDate = (date, name) => {
    setStateData((preState) => ({ ...preState, [name]: date }));
    setDisable(false);
  };
  const imagedata = {
    showImageModal,
    handleModel,
    onModalOk,
    multi: multiSelect,
  };

  return (
    <Paper
      elevation={0}
      style={{
        backgroundColor: "rgb(244, 245, 250)",
      }}
    >
      <div
        style={{
          backgroundColor: "rgb(244, 245, 250)",
          padding: "1.25rem 1.25rem 0.75rem",
          width: "auto",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            textTransform: "capitalize",
            margin: 0,
            fontFamily: "Inter, sans-serif",
            fontSize: "1.5rem",
            lineHeight: 1.334,
            fontWeight: 500,
          }}
        >
          {title}
          <div
            style={{
              fontSize: "0.8rem",
              fontWeight: 500,
              color: "rgba(58, 53, 65, 0.38)",
              paddingTop: 10,
            }}
          >
            {stateData.updated_by
              ? `Updated by ${stateData.updated_by?.name}`
              : stateData.created_by
                ? `Created by ${stateData.created_by?.name}`
                : " "}
            {stateData.updated_by && stateData?.updated_at
              ? TimeFormat(stateData?.updated_at)
              : stateData?.created_by && stateData?.created_at
                ? TimeFormat(stateData?.created_at)
                : " "}
          </div>
        </div>
        <div>
          <CustomButton
            color="success"
            onClick={handleSubmit}
            text={id ? "Update Car" : "Create Car"}
            disabled={disable}
          />
        </div>
      </div>
      <Divider />
      <div
        style={{
          padding: "10px 20px ",
          backgroundColor: "rgb(255, 255, 255)",
          margin: "20px ",
          boxShadow: "rgba(58, 53, 65, 0.1) 0px 2px 10px 0px",
          borderRadius: " 16px",
          transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        }}
      >
        <Grid container spacing={3} style={{ padding: "24px 0" }}>
          <Grid item xs={3}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel id="demo-simple-select-label">Car Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={stateData.car_type ? stateData.car_type : ""}
                label="Car Type"
                name="car_type"
                onChange={(event) => handleToggle(event, "car_type")}
              >
                <MenuItem value={"mini"}>Mini</MenuItem>
                <MenuItem value={"sedan"}>Sedan</MenuItem>
                <MenuItem value={"suv"}>SUV</MenuItem>
                <MenuItem value={"others"}>Others</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Car Brand</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={stateData?.brand ? stateData?.brand : ""}
                label="Car Brand"
                onChange={handleChange}
                name="brand"
              >
                {brands?.map((value, index) => (
                  <MenuItem key={index} value={value.value}>
                    {value.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="standard-basic"
              label="Model - En"
              variant="outlined"
              name="en_model_name"
              onChange={handleChange}
              value={stateData?.en_model_name ? stateData?.en_model_name : ""}
              style={{
                width: "100%",
              }}
              required
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="standard-basic"
              label="Model - Ar"
              variant="outlined"
              name="ar_model_name"
              onChange={handleChange}
              value={stateData?.ar_model_name ? stateData?.ar_model_name : ""}
              style={{
                width: "100%",
              }}
              required
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel id="demo-simple-select-label">Seat Count</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={stateData?.seat_count ? stateData?.seat_count : ""}
                label="Seat Count"
                name="seat_count"
                onChange={(event) => handleToggle(event, "seat_count")}
              >
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={7}>7</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="standard-basic"
              label="Engine Power"
              variant="outlined"
              name="engine_pr"
              onChange={handleChange}
              value={stateData?.engine_pr ? stateData?.engine_pr : ""}
              style={{
                width: "100%",
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="standard-basic"
              label="Horse Power"
              variant="outlined"
              name="horse_power"
              onChange={handleChange}
              value={stateData?.horse_power ? stateData?.horse_power : ""}
              style={{
                width: "100%",
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="standard-basic"
              label="VSB"
              variant="outlined"
              name="vsb"
              onChange={handleChange}
              value={stateData?.vsb ? stateData?.vsb : ""}
              style={{
                width: "100%",
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ padding: "24px 0" }}>
          <Grid item xs={3}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel id="demo-simple-select-label"> Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={stateData?.status ? stateData?.status : ""}
                label="Status"
                name="status"
                onChange={(event) => handleToggle(event, "status")}
              >
                <MenuItem value={"active"}>Active</MenuItem>
                <MenuItem value={"inactive"}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel id="demo-simple-select-label"> Offers</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={stateData?.offers ? stateData.offers : []}
                label="Offers"
                name="offers"
                onChange={(event) => handleToggle(event, "offers")}
                multiple
                MenuProps={MenuProps}
              >
                {offersList &&
                  offersList?.map((value) => (
                    <MenuItem key={value.id} value={value.id}>
                      {value.en_title}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel id="demo-simple-select-label">
                Insurance Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={
                  stateData?.insurance_type
                    ? stateData?.insurance_type
                    : stateData?.overview?.insurance_type
                }
                label="Insurance Type"
                onChange={handleChange}
                name="insurance_type"
              >
                <MenuItem value={"third-party"}>Third-Party Insurance</MenuItem>
                <MenuItem value={"comprehensive"}>
                  Comprehensive Insurance
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="standard-basic"
              label="RTO"
              variant="outlined"
              name="rto"
              onChange={handleOverView}
              value={stateData?.overview?.rto ? stateData?.overview?.rto : ""}
              style={{
                width: "100%",
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="standard-basic"
              label="Car Location"
              variant="outlined"
              name="car_location"
              onChange={handleOverView}
              value={
                stateData?.overview?.car_location
                  ? stateData?.overview?.car_location
                  : ""
              }
              style={{
                width: "100%",
              }}

            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="standard-basic"
              label="Price"
              variant="outlined"
              name="price"
              value={stateData?.price ? stateData?.price : ""}
              onChange={handleChange}
              style={{
                width: "100%",
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">OMR</InputAdornment>
                ),
              }}
              required
            />
          </Grid>
          <Grid item xs={3}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                views={["year"]}
                label="Model Year*"
                name="year"
                value={stateData?.year ? stateData?.year : ""}
                onChange={(newValue) => {
                  handleDate(newValue, "year");
                }}
                renderInput={(params) => (
                  <TextField
                    id="datePicl"
                    {...params}
                    helperText={null}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>

        <Grid container spacing={3} style={{ padding: "24px 0" }}>
          <Grid container xs={6} style={{ padding: "24px 24px 0 24px" }}>
            <Grid item xs={6} md={12} lg={6}>
              <p
                style={{
                  fontFamily: "Inter, sans-serif",
                  lineHeight: 1.5,
                  letterSpacing: "0.15px",
                  color: " rgba(58, 53, 65, 0.87)",
                  fontWeight: 400,
                  fontSize: " 1rem",
                  textAlign: "left",
                  margin: 0,
                }}
              >
                Fuel Type
              </p>
              <ToggleButtonGroup
                exclusive
                onChange={(event) => handleToggle(event, "fuel_type")}
                color="primary"
                name="transmission"
                value={stateData?.fuel_type ? stateData?.fuel_type : ""}
              >
                <ToggleButton
                  value="petrol"
                  // style={{ width: "100%", padding: "10px 40px" }}
                  style={{ width: "75px" }}
                >
                  Petrol
                </ToggleButton>
                <ToggleButton
                  value="diesel"
                  // style={{ width: "100%", padding: "10px 40px" }}
                  style={{ width: "75px" }}
                >
                  Diesel
                </ToggleButton>
                <ToggleButton
                  value="ev"
                  // style={{ width: "100%", padding: "10px 40px" }}
                  style={{ width: "45px" }}
                >
                  Ev
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={6} md={12} lg={6}>
              <p
                style={{
                  fontFamily: "Inter, sans-serif",
                  lineHeight: 1.5,
                  letterSpacing: "0.15px",
                  color: " rgba(58, 53, 65, 0.87)",
                  fontWeight: 400,
                  fontSize: " 1rem",
                  textAlign: "left",
                  margin: 0,
                }}
              >
                Transmission Type
              </p>
              <ToggleButtonGroup
                exclusive
                onChange={(event) => handleToggle(event, "transmission")}
                color="primary"
                name="transmission"
                value={stateData?.transmission ? stateData?.transmission : ""}
              >
                <ToggleButton
                  value="automatic"
                  style={{ width: "100%", padding: "10px 25px" }}
                >
                  Automatic
                </ToggleButton>
                <ToggleButton
                  value="manual"
                  style={{ width: "100%", padding: "10px 25px" }}
                >
                  Manual
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                  views={["day", "month", "year"]}
                  label="Car Make Year"
                  name="make_year"
                  value={
                    stateData.make_year
                      ? stateData?.make_year
                      : stateData?.overview?.make_year
                  }
                  onChange={(newValue) => {
                    handleDate(newValue, "make_year");
                  }}
                  renderInput={(params) => (
                    <TextField {...params} helperText={null} />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                  views={["year"]}
                  label="Car Register Year"
                  name="register_year"
                  value={
                    stateData.register_year
                      ? stateData?.register_year
                      : stateData?.overview?.register_year
                  }
                  onChange={(newValue) => {
                    handleDate(newValue, "register_year");
                  }}
                  renderInput={(params) => (
                    <TextField {...params} helperText={null} />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                  views={["day", "month", "year"]}
                  label="Insurance Validity"
                  name="insurance_validity"
                  value={
                    stateData.insurance_validity
                      ? stateData?.insurance_validity
                      : stateData?.overview?.insurance_validity
                  }
                  onChange={(newValue) => {
                    handleDate(newValue, "insurance_validity");
                  }}
                  disablePast
                  renderInput={(params) => (
                    <TextField {...params} helperText={null} />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="standard-basic"
                label="KM Driven"
                variant="outlined"
                name="km_driven"
                onChange={handleOverView}
                value={
                  stateData?.overview?.km_driven
                    ? stateData?.overview?.km_driven
                    : ""
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">KM</InputAdornment>
                  ),
                }}
                required
              />
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <p
              style={{
                fontFamily: "Inter, sans-serif",
                lineHeight: 1.5,
                letterSpacing: "0.15px",
                color: " rgba(58, 53, 65, 0.87)",
                fontWeight: 600,
                fontSize: " 1rem",
                textAlign: "center",
              }}
            >
              Title image
            </p>
            <ImageViewer
              placeholder="Upload Title Image"
              onHandleClick={() => {
                handleModel(false);
              }}
              name="title_image"
              value={stateData?.title_image ? stateData?.title_image : ""}
            />
          </Grid>
          <Grid item xs={3}>
            <p
              style={{
                fontFamily: "Inter, sans-serif",
                lineHeight: 1.5,
                letterSpacing: "0.15px",
                color: " rgba(58, 53, 65, 0.87)",
                fontWeight: 600,
                fontSize: " 1rem",
                textAlign: "center",
              }}
            >
              Banner image
            </p>
            <ImageViewer
              placeholder="Upload Banner Image"
              onHandleClick={() => {
                handleModel(true);
              }}
              name="images"
              value={stateData?.images ? stateData?.images : ""}
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={3}
          style={{ padding: "24px 0" }}
          height={500}
          marginBottom={10}
        >
          <Grid item xs={6}>
            <p className="tinymce-title">Car Description - En</p>
            <TinyMceField
              value={stateData?.en_description ? stateData?.en_description : ""}
              onChange={(e) => {
                handleChange({
                  target: {
                    name: "en_description",
                    value: e,
                  },
                });
              }}
              name="en_description"
              height="100%"
              width="100%"
            />
          </Grid>
          <Grid item xs={6}>
            <p className="tinymce-title">Car Description - Ar</p>
            <TinyMceField
              value={stateData?.ar_description ? stateData?.ar_description : ""}
              onChange={(e) => {
                handleChange({
                  target: {
                    name: "ar_description",
                    value: e,
                  },
                });
              }}
              name="ar_description"
              height="100%"
              width="100%"
            />
          </Grid>
        </Grid>
      </div>
      {showImageModal && <ImageModal {...imagedata} />}
      <ToastContainer style={{ zIndex: 999999 }} />
    </Paper>
  );
}
export default memo(PreOwnedForm);
