import React, { useState, useEffect, memo } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import { Modal, Pagination } from "antd";
import "antd/dist/antd.css";
import CustomButton from "components/inputs/customButton";
import { createImage, deleteIamges, fetchImageData } from "../../api/image";
import { getCookie } from "components/helper/cookies";
import { ToastContainer, toast } from "react-toastify";
import { toastStyle, createAccess } from "../helper";
import UploadFile from "components/upload/fileupload";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";
import noDataFound from "assets/images/notFound.svg";
import { MdDelete } from "react-icons/md";

const skeleton = [];
for (let i = 0; i < 15; i++) {
  skeleton.push(
    <Grid item xs={3} sm={3} md={3} lg={3} key={skeleton}>
      <Skeleton height={190} variant="rectangular" />
      <Skeleton
        animation="wave"
        height={15}
        style={{ marginBottom: 6, marginTop: 6 }}
      />
      <Skeleton animation="wave" height={15} width="70%" />
    </Grid>
  );
}
function Images({ searchName, handleLoader, handleSearchshow }) {
  const [image, setImage] = useState({ data: [] });
  const handleChild = React.createRef();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [total_entries, setTotalEntries] = useState();
  const [page, setPage] = useState(1);
  const [imageId, setImageId] = useState(1);
  const [search, setSearch] = useState({ query: "" });
  const perPage = 25;
  const path = window.location.pathname.split("/")[1];
  let controller = new AbortController();

  useEffect(() => {
    handleLoader(true);
    setLoading(true);
    handleSearchshow(true);
    handleSearch({ target: { value: searchName } });
    return () => {
      handleSearch({ target: { value: "" } });
    };
  }, [searchName]);
  useEffect(() => {
    handleSearch({ target: { value: "" } });
  }, [path]);
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch((preState) => ({ ...preState, query: value }));
  };

  useEffect(() => {
    fetchImage();
    return () => {
      controller.abort();
    };
  }, [page, search]);

  const showModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const showDeleteModal = (id) => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
    setImageId(id);
  };

  const handleOk = () => {
    const file = handleChild?.current?.requestParams();
    let form = new FormData();
    file.forEach((image) => {
      form.append("images[]", image);
    });
    createImage(getCookie("auth_token"), form)
      .then(({ message }) => {
        fetchImage();
        toast.success(message, toastStyle);
        setIsModalOpen(!isModalOpen);
      })
      .catch((err) => {
        if (err[1]) {
          toast.error(
            err[1] ? err[1]?.data[0] : "Something not right",
            toastStyle
          );
        } else {
          toast.error(err.message, toastStyle);
        }
      });
  };

  const handleOkDelete = () => {
    deleteIamges(getCookie("auth_token"), imageId)
      .then(({ message }) => {
        fetchImage();
        toast.success(message, toastStyle);
        setIsDeleteModalOpen(false);
      })
      .catch((err) => {
        if (err[1]) {
          toast.error(
            err[1] ? err[1]?.data[0] : "Something not right",
            toastStyle
          );
        } else {
          toast.error(err.message, toastStyle);
        }
      });
  };

  const fetchImage = () => {
    handleLoader(true);
    setLoading(true);
    const params = {
      page,
      perPage,
    };
    fetchImageData(getCookie("auth_token"), params, search, controller)
      .then(({ data, total_entries }) => {
        setImage((prestate) => ({
          ...prestate,
          data,
        }));
        setLoading(false);
        setTotalEntries(total_entries);
        handleLoader(false);
      })
      .catch((err) => {
        if (err[0] === 403 || err[0] === 401) {
          toast.error(err[1].message, toastStyle);
          kickUser();
        }
      });
  };
  const handlePageChange = (page) => {
    setPage(page);
    setLoading(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setIsDeleteModalOpen(false);
  };

  const { data } = image;

  return (
    <div>
      <Box
        style={{
          padding: "1.25rem 1.25rem 0.75rem",
          width: "auto",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            margin: 0,
            fontFamily: "Inter, sans-serif",
            fontSize: "1.5rem",
            lineHeight: 1.334,
            letterSpacing: "0em",
            fontWeight: 500,
          }}
        >
          Images
          {total_entries > 0 && (
            <span style={{ color: "rgba(58, 53, 65, 0.87)" }}>
              ({total_entries})
            </span>
          )}
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {createAccess("images") && (
            <CustomButton
              color="success"
              onClick={showModal}
              text="Upload Image"
            />
          )}
        </div>
      </Box>
      <div style={{ height: "100%", padding: "1.2rem", minHeight: "80vh" }}>
        <Divider />
        {total_entries === 0 && !loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "75vh",
              width: "100%",
            }}
          >
            <img src={noDataFound} style={{ width: "40%" }} />
          </div>
        )}

        {isModalOpen && (
          <Modal
            title="Image Upload"
            visible={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            closable={false}
            footer={[
              <CustomButton
                key="cancle"
                color="error"
                text="Cancel"
                onClick={handleCancel}
              />,
              <CustomButton
                key="submit"
                color="success"
                text="Submit"
                onClick={handleOk}
              />,
            ]}
          >
            <UploadFile ref={handleChild} />
          </Modal>
        )}

        {isDeleteModalOpen && (
          <Modal
            title="Image Delete"
            visible={isDeleteModalOpen}
            onOk={handleOkDelete}
            onCancel={handleCancel}
            closable={false}
            footer={[
              <CustomButton
                key="cancle"
                color="success"
                text="Cancel"
                onClick={handleCancel}
                outline={true}
              />,
              <CustomButton
                key="submit"
                color="error"
                text="Delete"
                onClick={handleOkDelete}
              />,
            ]}
          >
            {/* <UploadFile ref={handleChild} /> */}
            <h3 style={{ textAlign: "center" }}>
              Are you want to delete this image ??
            </h3>
          </Modal>
        )}

        <ImageList
          sx={{
            backgroundColor: "rgb(244, 245, 250)",
            gridTemplateColumns:
              "repeat(auto-fill,minmax(280px,1fr))!important",
          }}
          gap={15}
          style={{ padding: "30px 40px" }}
        >

          {loading ? (
            <>{skeleton}</>
          ) : (
            data?.map((item) => (
              <ImageListItem
                key={item.img}
                style={{
                  color: "rgb(33, 43, 54)",
                  transition:
                    "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                  backgroundColor: "white",
                  overflow: "hidden",
                  position: "relative",
                  boxShadow:
                    "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
                  borderRadius: " 10px",
                  zIndex: 0,
                }}
                id="image-container"
              >
                <div
                  style={{
                    position: "absolute",
                    top: 12,
                    right: 14,
                    border: "2px solid red",
                    padding: "6px 8px 2px 8px",
                    borderRadius: "50%",
                  }}
                  onClick={() => showDeleteModal(item.id)}
                  className="image-delete"
                >
                  <MdDelete style={{ cursor: "pointer" }} fontSize={"1.6rem"} color="red" />
                </div>
                <img
                  src={item.url}
                  srcSet={item.url}
                  alt={item.title}
                  loading="lazy"
                  style={{
                    height: "250px",
                    width: "100%",
                    objectFit: "scale-down",
                  }}
                />
                <ImageListItemBar title={item.name ? item.name : "-"} />
              </ImageListItem>
            ))
          )}
        </ImageList>
      </div>
      <ToastContainer style={{ zIndex: 999999 }} />
      <div>
        {total_entries != 0 && (
          <Pagination
            style={{ textAlign: "right", bottom: 30, marginRight: 20 }}
            defaultCurrent={1}
            current={page}
            defaultPageSize={perPage}
            onChange={handlePageChange}
            total={total_entries}
            showSizeChanger={false}
          />
        )}
      </div>
    </div>
  );
}
export default memo(Images);
