import makeRequest from "./index";

export function rentalCar(token, params, search, controller) {
  return makeRequest({
    uri: `/v1/admin/rentals?page=${params.page}&per_page=${params.perPage}&query=${search.query}`,
    method: "GET",
    authorization: token,
    controller: controller,
  });
}

export function rentalCarDetails(token, id) {
  return makeRequest({
    uri: `/v1/admin/rentals/${id}`,
    method: "GET",
    authorization: token,
  });
}

export function rentalCarCreate(token, requestBody) {
  return makeRequest({
    uri: `/v1/admin/rentals`,
    method: "POST",
    authorization: token,
    body: JSON.stringify(requestBody),
  });
}

export function rentalCarUpdate(token, requestBody, id) {
  return makeRequest({
    uri: `/v1/admin/rentals/${id}`,
    method: "PUT",
    authorization: token,
    body: JSON.stringify(requestBody),
  });
}

export function leasingCar(token, params, search, controller) {
  return makeRequest({
    uri: `/v1/admin/leasings?page=${params.page}&per_page=${params.perPage}&query=${search.query}`,
    method: "GET",
    authorization: token,
    controller: controller,
  });
}

export function leasingCarCreate(token, requestBody) {
  return makeRequest({
    uri: `/v1/admin/leasings`,
    method: "POST",
    authorization: token,
    body: JSON.stringify(requestBody),
  });
}

export function leasingCarUpdate(token, requestBody, id) {
  return makeRequest({
    uri: `/v1/admin/leasings/${id}`,
    method: "PUT",
    authorization: token,
    body: JSON.stringify(requestBody),
  });
}

export function leasingCarDetails(token, id) {
  return makeRequest({
    uri: `/v1/admin/leasings/${id}`,
    method: "GET",
    authorization: token,
  });
}

export function preOwnedCar(token, params, search, controller) {
  return makeRequest({
    uri: `/v1/admin/pre_owns?page=${params.page}&per_page=${params.perPage}&query=${search.query}`,
    method: "GET",
    authorization: token,
    controller: controller,
  });
}

export function media(token, params, search, controller){
  return makeRequest({
    uri: `/v1/admin/medias?page=${params.page}&per_page=${params.perPage}&query=${search.query}`,
    method: "GET",
    authorization: token,
    controller: controller,
  });
}

export function createMedia(token, requestBody){
  console.log(token, requestBody, 'check---------------->');
  
  return makeRequest({
    uri: `/v1/admin/medias`,
    method: "POST",
    authorization: token,
    body: JSON.stringify(requestBody),
  });
}

export function updateMedia(token,requestBody,id){
  return makeRequest({
    uri: `/v1/admin/medias/${id}`,
    method: "PUT",
    authorization: token,
    body: JSON.stringify(requestBody),
  });
}

export function mediaDetails(token, id){
  return makeRequest({
    uri: `/v1/admin/medias/${id}`,
    method: "GET",
    authorization: token,
  });
}

export function preOwnedCarCreate(token, requestBody) {
  return makeRequest({
    uri: `/v1/admin/pre_owns`,
    method: "POST",
    authorization: token,
    body: JSON.stringify(requestBody),
  });
}

export function preOwnedCarUpdate(token, requestBody, id) {
  return makeRequest({
    uri: `/v1/admin/pre_owns/${id}`,
    method: "PUT",
    authorization: token,
    body: JSON.stringify(requestBody),
  });
}

export function preOwnedCarDetails(token, id) {
  return makeRequest({
    uri: `/v1/admin/pre_owns/${id}`,
    method: "GET",
    authorization: token,
  });
}
