import makeRequest from "./index";
import { apiHost } from "config";

export function createImage(token, requestParams) {
  const headers = {
    Authorization: `API_KEY ${token}`,
  };
  return fetch(`${apiHost}/v1/admin/images`, {
    method: "POST",
    body: requestParams,
    headers: headers,
  }).then(async (response) => {
    if (response.ok) {
      return response.json();
    }
    const errorMessage = [response.status, await response.json()];
    throw errorMessage;
  });
}

export function fetchImageData(token, params, search,controller) {
  return makeRequest({
    uri: `/v1/admin/images?page=${params.page}&per_page=${params.perPage}&query=${search.query}`,
    method: "GET",
    authorization: token,
    controller:controller,
  });
}

export function deleteIamges(token, id) {
  return makeRequest({
    uri: `/v1/admin/images/${id}`,
    method: "DELETE",
    authorization: token,
  });
}