import config from "./config.json";
import person1 from "assets/userImage/person1.png";
import person2 from "assets/userImage/person2.png";
import person3 from "assets/userImage/person3.png";
import person4 from "assets/userImage/person4.png";
import person5 from "assets/userImage/person5.png";
import person6 from "assets/userImage/person6.png";
import person7 from "assets/userImage/person7.png";
import person8 from "assets/userImage/person7.png";

// export const apiHost = "https://api-stage-sayarti.katomaran.in";
export const apiHost = config.host;
export const tinyMcKey = config.tiny_api_key;

export const UserImage = [
    person1,
    person2,
    person3,
    person4,
    person5,
    person6,
    person7,
    person8,
  ];