import React, { useState, useEffect, memo } from "react";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import CustomButton from "../inputs/customButton";
import ImageModal from "components/images/Imagemodal";
import { ImageViewer } from "components/upload/imageViewer";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { getCookie } from "components/helper/cookies";
import {  createMedia, mediaDetails, updateMedia } from "api/car";
import { useNavigate } from "react-router-dom";
import { Skeleton } from 'antd';
function MediaForm({ handleLoader }) {
    const path = window.location.pathname.split("/")[2];
    const isNew = path === "new";
    const [loading, setLoading] = useState(!isNew);
    const [stateData, setStateData] = useState({
      title: "",
      description: "",
      bannerImage: {},
      thumbnailImage: {},
      carouselImages: [],
      publishAt: null,
      status: "",
    });
    const [errors, setErrors] = useState({});
    const [showImageModal, setShowImageModal] = useState(false);
    const [multiSelect, setMultiSelect] = useState(false);
    const [imageType, setImageType] = useState("");
    let navigate = useNavigate();
  
    const fetchupdatedMedia = () => {
      const path = window.location.pathname.split("/")[2];
      mediaDetails(getCookie("auth_token"), path)
        .then((data) => {
            console.log(data.data.status, 'data---------->' );
            const date = new Date(data.data.published_at);
            const formattedDate = `${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getDate()).padStart(2, '0')}/${date.getFullYear()}`;
          setStateData({
            title: data.data.title || "",
            description: data.data.description || "",
            bannerImage: data.data.banner_image || "",
            thumbnailImage: data.data.title_image || "",
            carouselImages: data.data.carousel_images_list || [],
            publishAt: formattedDate ? formattedDate : null,
            status:data.data.status || "",
          });
          setLoading(false);
        })
        .catch((error) => {
          console.log(error, "error");
          toast.error("Failed to load media details.");
          setLoading(false);
        });
    };
  
    useEffect(() => {
      if (!isNew) {
        setLoading(true);
        fetchupdatedMedia();
      }
    }, [path, isNew]);
  
    const handleModel = (info, type) => {
      setMultiSelect(info);
      setImageType(type);
      setShowImageModal(!showImageModal);
    };

    const handleStatus = (newStatus) => {    
      if (newStatus !== null) {
        setStateData((prevState) => ({ ...prevState, status: newStatus })); 
      }
  };
  
    const handleChange = (event) => {
      const { name, value } = event.target;
      setStateData((prevState) => ({ ...prevState, [name]: value }));
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    };
  
    // const handleDateChange = (newDate) => {
    //   setStateData((prevState) => ({ ...prevState, publishAt: newDate }));
    // };
  
    const onModalOk = (image_name) => {
      if (multiSelect) {
        setStateData((prevState) => ({
          ...prevState,
          carouselImages: image_name.multiImageID,
        }));
      } else {
        setStateData((prevState) => ({
          ...prevState,
          [imageType]: image_name,
        }));
      }
      setShowImageModal(false);
    };
  
    const validateFields = () => {
      const newErrors = {};
    
      if (!stateData.title.trim()) newErrors.title = "Title is required";
      if (!stateData.description.trim()) newErrors.description = "Description is required";
      // if (!stateData.publishAt) newErrors.publishAt = "Publish date is required";
      if (!stateData.bannerImage || !stateData.bannerImage.id) newErrors.bannerImage = "Banner Image is required";
      if (!stateData.thumbnailImage || !stateData.thumbnailImage.id) newErrors.thumbnailImage = "Thumbnail Image is required";
      if (!stateData.carouselImages.length) newErrors.carouselImages = "At least one carousel image is required";

      setErrors(newErrors);
      if (!stateData.bannerImage || !stateData.bannerImage.id) {
        toast.error("Please upload a Banner Image.");
        return
      }
      if (!stateData.thumbnailImage || !stateData.thumbnailImage.id) {
        toast.error("Please upload a Thumbnail Image.");
        return
      }
      if (!stateData.carouselImages.length) {
        toast.error("Please upload at least one Carousel Image.");
        return
      }
    
      return Object.keys(newErrors).length === 0;
    };
    
  
    const handleSubmit = () => {
        if (!validateFields()) {
            // toast.error("Please fill in all required fields.");
            return;
        }
    
        const formattedPublishAt = stateData.publishAt 
        ? new Date(stateData.publishAt).toISOString() 
        : new Date().toISOString();
    
        const requestBody = {
            medias: {
                title: stateData.title,
                description: stateData.description,
                published_at: formattedPublishAt,
                title_image_id: stateData.thumbnailImage.id,
                banner_image_id: stateData.bannerImage.id,
                carousel_images: stateData.carouselImages.map(image => image.id),
                status: stateData.status,
            }
        };
    
        console.log(requestBody, 'requestBody');
    
        const path = window.location.pathname.split("/")[2];
        handleLoader(true);
        const media = isNew ? createMedia : updateMedia;
    
        media(getCookie("auth_token"), requestBody, path)
            .then(() => {
                toast.success(isNew ? "Media created successfully!" : "Media updated successfully!");
                navigate('/media');
                handleLoader(false);
            })
            .catch((error) => {
                console.log(error, 'error------------------->');
                toast.error("Failed to submit media.");
                handleLoader(false);
            });
    };
    
  
    if (loading) return <Skeleton />;
  
    return (
      <Paper elevation={0} style={{ backgroundColor: "rgb(244, 245, 250)" }}>
        <div style={{ padding: "1.25rem", display: "flex", justifyContent: "space-between" }}>
          <h2>{isNew ? "Create Media" : "Update Media"}</h2>
          <CustomButton color="success" onClick={handleSubmit} text={isNew ? "CREATE" : "UPDATE"} />
        </div>
        <Divider />
        <div style={{ padding: "20px" }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                label="Title"
                variant="outlined"
                name="title"
                onChange={handleChange}
                value={stateData.title}
                fullWidth
                required
                error={!!errors.title}
                helperText={errors.title}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Description"
                variant="outlined"
                name="description"
                onChange={handleChange}
                value={stateData.description}
                fullWidth
                multiline
                rows={4}
                required
                error={!!errors.description}
                helperText={errors.description}
              />
            </Grid>
            <Grid item xs={12}>
            <div style={{ padding: "10px" }}>
                <ToggleButtonGroup
                    value={stateData.status}
                    exclusive
                    onChange={(e, newStatus) => handleStatus(newStatus)}
                    color="primary"
                    name="status"
                >
                    <ToggleButton value="active" style={{ width: "100px" }}>
                        Active
                    </ToggleButton>
                    <ToggleButton value="inactive" style={{ width: "100px" }}>
                        InActive
                    </ToggleButton>
            </ToggleButtonGroup>
            </div>
            </Grid>
            <Grid item xs={4}>
            <p
              style={{
                fontFamily: "Inter, sans-serif",
                lineHeight: 1.5,
                letterSpacing: "0.15px",
                color: " rgba(58, 53, 65, 0.87)",
                fontWeight: 600,
                fontSize: " 1rem",
                textAlign: "center",
              }}
            >
              Banner Image
            </p>
              <ImageViewer
                placeholder="Upload Banner Image"
                onHandleClick={() => handleModel(false, "bannerImage")}
                name="bannerImage"
                value={stateData.bannerImage}
                error={!!errors.bannerImage}
                helperText={errors.bannerImage}
              />
            </Grid>
            <Grid item xs={4}>
            <p
              style={{
                fontFamily: "Inter, sans-serif",
                lineHeight: 1.5,
                letterSpacing: "0.15px",
                color: " rgba(58, 53, 65, 0.87)",
                fontWeight: 600,
                fontSize: " 1rem",
                textAlign: "center",
              }}
            >
              Thumbnail Image
            </p>
              <ImageViewer
                placeholder="Upload Thumbnail Image"
                onHandleClick={() => handleModel(false, "thumbnailImage")}
                name="thumbnailImage"
                value={stateData.thumbnailImage}
                error={!!errors.thumbnailImage}
                helperText={errors.thumbnailImage}
              />
            </Grid>
            <Grid item xs={4}>
            <p
              style={{
                fontFamily: "Inter, sans-serif",
                lineHeight: 1.5,
                letterSpacing: "0.15px",
                color: " rgba(58, 53, 65, 0.87)",
                fontWeight: 600,
                fontSize: " 1rem",
                textAlign: "center",
              }}
            >
              Carousel Images
            </p>
              <ImageViewer
                placeholder="Upload Carousel Images"
                onHandleClick={() => handleModel(true)}
                name="carouselImages"
                value={stateData?.carouselImages ? stateData?.carouselImages : ""}
                multiple
              />
            </Grid>
          </Grid>
        </div>
        {showImageModal && (
          <ImageModal
            showImageModal={showImageModal}
            onModalOk={onModalOk}
            multi={multiSelect}
            handleModel={handleModel}
          />
        )}
        <ToastContainer style={{ zIndex: 999999 }} />
      </Paper>
    );
  }
  
  export default memo(MediaForm);
  