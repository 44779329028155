import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Card from "./card";
import { media } from "api/car";
import { getCookie, kickUser } from "components/helper/cookies";
import { toast, ToastContainer } from "react-toastify";

const Media = ({ searchName, handleSearchshow, handleLoader }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalEntries, setTotalEntries] = useState(0);
    const [search, setSearch] = useState({ query: "" });
    const [page, setPage] = useState(1);
    const perPage = 24;
    let navigate = useNavigate();
    const path = window.location.pathname.split("/")[1];
    let controller = new AbortController();

     useEffect(() => {
        handleSearchshow(true);
        handleLoader(true);
        setLoading(true);
        handleSearch({ target: { value: searchName } });
      }, [searchName]);

       useEffect(() => {
          handleSearch({ target: { value: "" } });
        }, [path]);

      useEffect(() => {
        const params = {
            page,
            perPage,
          };
          media(getCookie("auth_token"), params, search,controller)
              .then(({ data, total_entries }) => {
                  setData(data);
                  setTotalEntries(total_entries);
                  setLoading(false);
                  handleLoader(false);
              })
              .catch((err) => {
                  if (err[0] === 403 || err[0] === 401) {
                      toast.error(err[1].message, toastStyle);
                      kickUser();
                  }
              });
              return () => {
                  controller.abort();
                };
      }, [path, search]);

    const handleCreate = () => {
        navigate("/media/new");
    };

    const handleSearch = (e) => {
        const { value } = e.target;
        setSearch((preState) => ({ ...preState, query: value }));
      };


    const handlePageChange = (page) => {
        setPage(page);
        setLoading(true);
        handleLoader(true);
      };


    const preownedProps = {
        data,
        total_entries: totalEntries,
        loading,
        title: "Media List",
        handleCreate,
        createAction: true,
        handlePageChange,
        page,
        menu: "medias",
        perPage
    };

    return (
        <Paper
            elevation={0}
            style={{
                backgroundColor: "rgb(244, 245, 250)",
            }}
        >
            <Card {...preownedProps} />
            <ToastContainer style={{ zIndex: 999999 }} />
        </Paper>
    );
};

export default Media;