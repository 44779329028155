import React, { useEffect, useState, memo } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CustomButton from "components/inputs/customButton";
// import gear from "assets/images/gear.svg";
import {
  titleize,
  presence,
  toastStyle,
  updateAccess,
} from "components/helper";
// import LocalGasStationOutlinedIcon from "@mui/icons-material/LocalGasStationOutlined";
// import { FaCarSide } from "react-icons/fa";
import ImageSearchOutlinedIcon from "@mui/icons-material/ImageSearchOutlined";
import { Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import noDataFound from "assets/images/notFound.svg";
import { Pagination } from "antd";

const skeleton = [];
for (let i = 0; i < 12; i++) {
  skeleton.push(
    <div
      key={i}
      style={{
        flexBasis: "24.33%",
        flexGrow: 0,
        display: "flex",
        flexDirection: "column",
        color: "rgb(33, 43, 54)",
        backgroundColor: "transparent",
        justifyContent: "space-between",
      }}
      className="card-body"
    >
      <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />
      <Skeleton
        animation="wave"
        height={15}
        style={{ marginBottom: 6, marginTop: 6 }}
      />
      <Skeleton animation="wave" height={15} width="70%" />
    </div>
  );
}

function truncateDescription(description) {
  const firstSentence = description.split('. ')[0];
  return firstSentence + (description.includes('. ') ? '...' : '');
}

function CardTemplate({
  data,
  total_entries,
  loading,
  title,
  handleCreate,
  createAction,
  menu,
  handlePageChange,
  perPage,
  page,
}) {
  const [path, setPath] = useState("");
  let navigate = useNavigate();
  useEffect(() => {
    setPath(window.location.pathname.split("/")[1]);
  }, []);
  

const getformateddate = (data) => {
  const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
  const formattedDate = new Date(data).toLocaleDateString('en-GB', options);
  console.log(data, formattedDate, 'formattedDate');
  
  return formattedDate;
}
  console.log(data);
  
  return (
    <Paper
      elevation={0}
      style={{
        backgroundColor: "rgb(244, 245, 250)",
        height: "100%",
      }}
    >
      <Box
        style={{
          padding: "1.25rem 1.25rem 0.75rem",
          width: "auto",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            margin: 0,
            fontFamily: "Inter, sans-serif",
            fontSize: "1.5rem",
            lineHeight: 1.334,
            letterSpacing: "0em",
            fontWeight: 500,
          }}
        >
          {title}
          {total_entries > 0 && (
            <span style={{ color: "rgba(58, 53, 65, 0.87)" }}>
              ({total_entries})
            </span>
          )}
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {createAction && (
            <CustomButton
              color="success"
              onClick={handleCreate}
              text="Add Media"
            />
          )}
        </div>
      </Box>

      <div style={{ padding: "1.2rem", height: "100%", minHeight: "80vh" }}>
        <Divider />
        {total_entries === 0 && !loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "75vh",
              width: "100%",
            }}
          >
            <img src={noDataFound} style={{ width: "40%" }} />
          </div>
        )}

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: 10,
            paddingTop: "20px",
          }}
        >
          {loading ? (
            <>{skeleton}</>
          ) : (
            data?.map((value, index) => (
              <div
                key={index}
                style={{
                  flexBasis: "24.33%",
                  flexGrow: 0,
                  display: "flex",
                  flexDirection: "column",
                  color: "rgb(33, 43, 54)",
                  boxShadow:
                    "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
                  borderRadius: "16px",
                  zIndex: 0,
                  backgroundColor: "white",
                  justifyContent: "space-between",
                  padding: "10px 24px",
                }}
                className="card-body"
                onClick={() => {
                  if (updateAccess(menu)) {
                    navigate(`/${path}/${value.id}/update`);
                  } else {
                    toast.error("Access Denied", toastStyle);
                  }
                }} 
              >
                <div
                  style={{
                    textAlign: "center",
                    cursor: "pointer",
                    overflow: "hidden",
                  }}
                  className="card-content"
                >
                  {value?.title_image?.url ? (
                    <img
                      src={value?.title_image?.url}                     
                      style={{
                        width: "100%",
                        height: "200px",
                        objectFit: "scale-down",
                      }}
                    />
                  ) : (
                    <ImageSearchOutlinedIcon
                      style={{
                        width: "100%",
                        height: "200px",
                      }}
                    />
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                  }}
                >
                  <h4 className="car-model">
                    {value?.title}
                  </h4>
                  <Typography variant="body2" color="textSecondary">
                    Published: {getformateddate(value.published_at)}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Updated: {getformateddate(value.updated_at)}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Status: {titleize(presence(value.status))}
                  </Typography>
                  <Typography className="description" variant="body2" color="textSecondary">
                    {truncateDescription(value.description, 4)}
                  </Typography>
                </div>
                
              </div>
            ))
          )}
        </div>
      </div>
      <ToastContainer style={{ zIndex: 999999 }} />
      {total_entries !== 0 && (
        <Pagination
          style={{ textAlign: "right", bottom: 30, marginRight: 20 }}
          defaultCurrent={1}
          current={page}
          defaultPageSize={perPage}
          onChange={handlePageChange}
          total={total_entries}
          showSizeChanger={false}
        />
      )}
    </Paper>
  );
}

export default memo(CardTemplate);