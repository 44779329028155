import React from "react";
import { fetchRoleGroup } from "./api/adminLogin";
import DirectionsCarFilledOutlinedIcon from "@mui/icons-material/DirectionsCarFilledOutlined";
import PhotoSizeSelectActualOutlinedIcon from "@mui/icons-material/PhotoSizeSelectActualOutlined";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import PhoneInTalkOutlinedIcon from "@mui/icons-material/PhoneInTalkOutlined";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import Images from "./components/images/images";
import User from "./views/user/user";
import RentalCar from "./views/carType/rentalCar";
import LeasingCar from "./views/carType/leasingCar";
import PreOwnedCar from "./views/carType/pre-ownedCar";
import { getCookie } from "components/helper/cookies";
import RentalForm from "components/forms/rentalForm";
import LeasingForm from "components/forms/leasingForm";
import PreOwnedForm from "components/forms/preOwnedForm";
import Profile from "components/loginModules/profile";
import Offers from "views/offer";
import OfferForm from "components/forms/offerForm";
import Enquiries from "views/enquires";
import EnquiriesForm from "components/forms/enquiriesForm";
import GroupsIcon from "@mui/icons-material/Groups";
import Customers from "views/customers";
import CustomerDetails from "components/customer/customerDetails";
import Media from "components/media/Media"
import MediaForm from "components/forms/mediaForm";

const adminMenu = [
  {
    index: 1,
    name: "Rental Cars",
    icon: <PaymentsOutlinedIcon />,
    groupId: "master_data",
    url: "/rentalcar",
    match_url: "rentalcar",
    permissions: {
      "v1/admin/rentals": [
        "show",
        "create",
        "index",
      ],
    },
    render: (props) => <RentalCar {...props} />,
  },
  {
    index: 2,
    name: "Leasing Cars",
    icon: <HandshakeOutlinedIcon />,
    groupId: "master_data",
    url: "/leasingcar",
    match_url: "leasingcar",
    permissions: {
      "v1/admin/leasings": ["index", "create", "show"],
    },
    render: (props) => <LeasingCar {...props} />,
  },
  {
    index: 3,
    name: "Pre-Owned Cars",
    icon: <DirectionsCarFilledOutlinedIcon />,
    groupId: "master_data",
    url: "/preownedcar",
    match_url: "preownedcar",
    permissions: {
      "v1/admin/pre_owns": ["create", "index", "show"],
    },
    render: (props) => <PreOwnedCar {...props} />,
  },
  {
    index: 4,

    name: "Images",
    icon: <PhotoSizeSelectActualOutlinedIcon />,
    groupId: "master_data",
    url: `/images`,
    match_url: "images",
    permissions: {
      "v1/admin/images": ["index", "create", "update"],
    },
    render: (props) => <Images {...props} />,
  },
  {
    index: 5,
    name: "Enquiries",
    icon: <PhoneInTalkOutlinedIcon />,
    groupId: "enquiries",
    url: `/enquiries`,
    match_url: "enquiries",
    permissions: {
      "v1/admin/enquiries": [
        "show",
        "index",
        "conversation",
        "list_conversation",
        "sales_users",
      ],
    },
    render: (props) => <Enquiries {...props} />,
  },
  {
    index: 7,
    name: "Offers",
    icon: <CampaignOutlinedIcon />,
    groupId: "master_data",
    url: `/offers`,
    match_url: "offers",
    permissions: {
      "v1/admin/offers": ["index", "show"],
    },
    render: (props) => <Offers {...props} />,
  },
  {
    index: 8,
    name: "Users",
    icon: <AccountBoxOutlinedIcon />,
    groupId: "admin",
    url: `/user`,
    match_url: "user",
    permissions: {
      "v1/admin/users": ["index", "show"],
    },
    render: (props) => <User {...props} />,
  },
  {
    index: 9,
    name: "Customers",
    icon: <GroupsIcon />,
    groupId: "enquiries",
    url: `/customers`,
    match_url: "customers",
    permissions: {
      "v1/admin/customers": ["index","show"],
    },
    render: (props) => <Customers {...props} />,
  },
  {
    index: 10,
    name: "Media",
    icon: <GroupsIcon />,
    groupId: "media",
    url: `/media`,
    match_url: "media",
    permissions: {
      "v1/admin/customers": ["index","show"],
    },
    render: (props) => <Media {...props} />,
  },
  {
    url: '/media/new',
    match_url: '/media/new',
    permissions: {
      "v1/admin/medias": ["create"],
    },
    render: (props) => <MediaForm {...props} mode="create" />,
  },
  {
    url: '/media/:id/update',
    match_url: '/media/:id/update',
    permissions: {
      "v1/admin/medias": ["update"],
    },
    render: (props) => <MediaForm {...props} mode="update" />,
  },
  
  {
    url: `/rentalcar/new`,
    match_url: "/rentalcar/new",
    permissions: {
      "v1/admin/rentals": ["create"],
    },
    render: (props) => <RentalForm {...props} />,
  },
  {
    url: `/rentalcar/:id/update`,
    match_url: "/rentalcar/:id/update",
    permissions: {
      "v1/admin/rentals": ["update"],
    },
    render: (props) => <RentalForm {...props} />,
  },
  {
    url: `/leasingcar/new`,
    match_url: "/leasingcar/new",
    permissions: {
      "v1/admin/leasings": ["create"],
    },
    render: (props) => <LeasingForm {...props} />,
  },
  {
    url: `/leasingcar/:id/update`,
    match_url: "/leasingcar/:id/update",
    permissions: {
      "v1/admin/leasings": ["update"],
    },
    render: (props) => <LeasingForm {...props} />,
  },
  {
    url: `/preownedcar/new`,
    match_url: "/preownedcar/new",
    permissions: {
      "v1/admin/pre_owns": ["create"],
    },
    render: (props) => <PreOwnedForm {...props} />,
  },
  {
    url: `/preownedcar/:id/update`,
    match_url: "/preownedcar/:id/update",
    permissions: {
      "v1/admin/pre_owns": ["update"],
    },
    render: (props) => <PreOwnedForm {...props} />,
  },
  {
    url: `/profile`,
    match_url: "/profile",
    permissions: {
      "v1/admin/users": ["update_password", "profile", "update_profile"],
    },
    render: (props) => <Profile {...props} />,
  },
  {
    url: `/offers/createoffer`,
    match_url: "/offers/createoffer",
    permissions: {
      "v1/admin/offers": ["create", "show"],
    },
    render: (props) => <OfferForm {...props} />,
  },
  {
    url: `/offers/:id/updateOffer`,
    match_url: "/offers/:id/updateOffer",
    permissions: {
      "v1/admin/offers": ["update", "create", "show"],
    },
    render: (props) => <OfferForm {...props} />,
  },
  {
    url: `/enquiries/:id`,
    match_url: "/enquiries/:id",
    permissions: {
      "v1/admin/enquiries": [
        "show",
        "update_status",
        "index",
        "conversation",
        "list_conversation",
        "sales_users",
      ],
    },
    render: (props) => <EnquiriesForm {...props} />,
  },
  {
    url: `/customers/:id`,
    match_url: "/customers/:id",
    permissions: {
      "v1/admin/customers": ["show"],
    },
    render: (props) => <CustomerDetails {...props} />,
  },
];

const localPromise = new Promise((res) => {
  try {
    const data = JSON.parse(window.localStorage.getItem("user_roles"));
    if (!data || "object" !== typeof data) {
      res([false]);
    }
    res([true, data]);
  } catch (err) {
    res([false]);
  }
});

export const filteredMenu = async () => {
  let [status, userRoles] = await localPromise;
  if (!status) {
    await fetchRoleGroup(getCookie("auth_token"))
      .then(({ data }) => {
        userRoles = data;

        window.localStorage.setItem("user_roles", JSON.stringify(userRoles));
      })
      .catch((err) => {
        if (err[0] === 403 || err[0] === 401) {
          window.location.pathname = "/";
        }
      });
  }
    const menuData = adminMenu?.filter((value) => {
    return Object.keys(value?.permissions)?.every((permission) => {
      return (
        Object.keys(userRoles)?.includes(permission) &&
        value.permissions[permission]?.every((sub_permission) =>
          userRoles[permission].includes(sub_permission)
        )
      );
    });
  });
  return menuData;
};
