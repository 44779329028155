import moment from "moment";
import { getCookie } from "./cookies";

const jsonHeaders = {
  Accept: "application/json",
  "Content-type": "application/json",
};

const toastStyle = {
  position: "bottom-left",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

function SliceName(str) {
  if (!str) {
    return "-";
  }
  if (str.length > 18) {
    return str.slice(0, 18) + "....";
  }
  return str;
}
function SliceContent(str) {
  if (!str) {
    return "-";
  }
  if (str.length > 40) {
    return str.slice(0, 40) + "....";
  }
  return str;
}
function DateTime(time) {
  if (!time) {
    return "-";
  } else {
    return moment(time).format("DD-MMM, hh:ss A");
  }
}
function shuffleArray(array) {
  let i = array.length - 1;
  for (; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
}

function titleize(str) {
  if (!str) {
    return str;
  }

  return str
    .split(" ")
    .map(function (string) {
      return string?.charAt(0).toUpperCase() + string.slice(1);
    })
    .join(" ")
    .split("_")
    .map(function (string) {
      return string?.charAt(0).toUpperCase() + string.slice(1);
    })
    .join(" ");
}

function deleteAllCookie() {
  document.cookie.split(";").forEach(function (c) {
    document.cookie = c
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });
}

export function presence(string) {
  if (string === "undefined") {
    return "-";
  }
  return string?.length > 0 ? string : "-";
}
export function isAuthenticated() {
  return !!getCookie("auth_token");
}
function TimeFormat(time) {
  if (!time) {
    return "";
  } else {
    const current = moment();
    return (
      moment.duration(moment(time).diff(moment(current))).humanize() + " ago"
    );
  }
}

export function createAccess(action) {
  let user_roles = window.localStorage.getItem("user_roles");
  user_roles = user_roles && JSON.parse(user_roles);
  let model = user_roles && user_roles[`v1/admin/${action}`];
  return model.includes("create");
}

export function updateAccess(action) {
  console.log('innnn');
  
  let user_roles = window.localStorage.getItem("user_roles");
  user_roles = user_roles && JSON.parse(user_roles);
  let model = user_roles && user_roles[`v1/admin/${action}`];
  console.log(model, 'model---------->');
  return model.includes("update");
}

export function assignMember(action) {
  let user_roles = window.localStorage.getItem("user_roles");
  user_roles = user_roles && JSON.parse(user_roles);
  let model = user_roles && user_roles[`v1/admin/${action}`];
  return model.includes("assign_member");
}

export {
  jsonHeaders,
  toastStyle,
  SliceName,
  deleteAllCookie,
  titleize,
  DateTime,
  shuffleArray,
  TimeFormat,
  SliceContent
};
