import makeRequest from "./index";

export function fetchBrands(token, params, search,controller) {
  return makeRequest({
    uri: `/v1/admin/car_brands?page=${params?.page || 1}&per_page=${params?.perPage || 100}&query=${search?.query || " "}`,
    method: "GET",
    authorization: token,
    controller:controller,
  });
}

export function createBrand(token, requestBody) {
  return makeRequest({
    uri: `/v1/admin/car_brands`,
    method: "POST",
    authorization: token,
    body: JSON.stringify(requestBody),
  });
}