import React, { useState, useEffect, memo } from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Logo from "../../assets/images/logo.png";
import { Divider } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { NavLink, useNavigate } from "react-router-dom";
import { filteredMenu } from "menu";

const typoGraphyStyle = {
  margin: "0px",
  fontFamily: "'Inter', sans-serif",
  fontWeight: 400,
  fontSize: "1.1rem",
  lineHeight: 1.5,
  letterSpacing: "0.15px",
  color: "rgba(58, 53, 65, 0.87)",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};

function LayOut() {
  let navigate = useNavigate();
  let selectedKey = window.location.pathname.split("/")[1];
  const [menuGroups, setMenuGroups] = useState([]);
  const menuId = menuGroups.filter((menu) => menu.groupId);
  let masterData = menuId.filter((a) => a.groupId === "master_data");
  const enquiry = menuId.filter((a) => a.groupId === "enquiries");
  const admin = menuId.filter((a) => a.groupId === "admin");

  const media = menuId.filter((a) => a.groupId === "media");

  useEffect(() => {
    const getUsers = async () => {
      let menuGroup = await filteredMenu();
      
      setMenuGroups(menuGroup);
    };
    getUsers();
  }, []);
  return (
    <List
      sx={{
        width: "100%",
        maxWidth: 360,
        bgcolor: "transparent",
        height: "100%",
        paddingTop: 0,
      }}
      component="nav"
      defaultChecked={selectedKey}
    >
      <div
        style={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "15px",
        }}
      >
        {/* <Button > */}
        <Avatar
          onClick={() => navigate("/rentalcar")}
          src={Logo}
          style={{ width: "9rem", height: "auto" }}
        />
        {/* </Button> */}
      </div>

      <div style={{ height: "90vh", overflow: "auto" }}>
        <List style={{ paddingTop: "15px" }}>
          {masterData.length > 0 && (
            <Divider style={{ margin: "20px 0" }} className="menu-subheader">
              Master Data
            </Divider>
          )}
          {masterData?.map((group, i) => (
            <div key={i}>
              {group.index && (
                <NavLink to={group?.url} className="menu-item">
                  <ListItemButton selected={group.match_url === selectedKey}>
                    <ListItemIcon>{group.icon}</ListItemIcon>
                    <ListItemText
                      primary={group.name}
                      primaryTypographyProps={typoGraphyStyle}
                    />
                  </ListItemButton>
                </NavLink>
              )}
            </div>
          ))}
          {enquiry.length > 0 && (
            <Divider style={{ margin: "20px 0" }} className="menu-subheader">
              Enquiries
            </Divider>
          )}
          {enquiry?.map((group, i) => (
            <div key={i}>
              {group.index && (
                <NavLink to={group?.url} className="menu-item">
                  <ListItemButton selected={group.match_url === selectedKey}>
                    <ListItemIcon>{group.icon}</ListItemIcon>
                    <ListItemText
                      primary={group.name}
                      primaryTypographyProps={typoGraphyStyle}
                    />
                  </ListItemButton>
                </NavLink>
              )}
            </div>
          ))}

          {admin.length > 0 && (
            <Divider style={{ margin: "20px 0" }} className="menu-subheader">
              Admin
            </Divider>
          )}
          {admin?.map((group, i) => (
            <div key={i}>
              {group.index && (
                <NavLink to={group?.url} className="menu-item">
                  <ListItemButton selected={group.match_url === selectedKey}>
                    <ListItemIcon>{group.icon}</ListItemIcon>
                    <ListItemText
                      primary={group.name}
                      primaryTypographyProps={typoGraphyStyle}
                    />
                  </ListItemButton>
                </NavLink>
              )}
            </div>
          ))}
        </List>
        <Divider style={{ margin: "20px 0" }} className="menu-subheader">
              Media
        </Divider>
        {media?.map((group, i) => (
            <div key={i}>
              {group.index && (
                <NavLink to={group?.url} className="menu-item">
                  <ListItemButton selected={group.match_url === selectedKey}>
                    <ListItemIcon>{group.icon}</ListItemIcon>
                    <ListItemText
                      primary={group.name}
                      primaryTypographyProps={typoGraphyStyle}
                    />
                  </ListItemButton>
                </NavLink>
              )}
            </div>
          ))}
      </div>
    </List>
  );
}
export default memo(LayOut);
